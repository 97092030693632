<template>
  <div :key="key">
    <section class="section is-main-section">
      <h1 class="title" v-if="!command || !'REJECT,FORWARD'.includes(command)">
        {{ shipment && shipment.id ? "ແກ້ໄຂບິນ " : "ສ້າງບິນ " }} ຮັບຝາກເຄື່ອງ
      </h1>
      <h1 class="title" v-if="command && command === 'REJECT'">
        ສ້າງບິນເຄື່ອງຝາກສົ່ງກັບຄືນຕົ້ນທາງ
      </h1>
      <h1 class="title" v-if="command && command === 'FORWARD'">
        ສ້າງບິນເຄື່ອງຝາກສົ່ງຕໍ່
      </h1>
      <div class="columns">
        <div class="column">
          <div class="panel is-primary">
            <div class="panel-heading">
              ຕົ້ນທາງ
              <b-icon
                v-if="!shipment.id"
                icon="magnify"
                class="is-pulled-right"
                @click.native="showCustomers()"
              />
            </div>
            <div class="" style="padding: 1em">
              <b-field
                label="ສາຂາຕົ້ນທາງ"
                horizontal
                v-if="shipment.from_branch"
              >
                {{ shipment.from_branch.code }} -
                {{ shipment.from_branch.name }}
              </b-field>
              <b-field label="ເບີໂທຜູ້ສົ່ງ" horizontal>
                <b-input
                  v-model="shipment.sendertel"
                  placeholder="20xxxxxxxx"
                  @keyup.native.enter="searchCustomer()"
                  @blur="searchCustomer()"
                />

                <p class="control" v-if="this.customer">
                  <span class="tag is-primary">ລູກຄ້າລົງທະບຽນ</span>
                </p>
                <p class="control" v-else>
                  <span class="tag is-light">ລູກຄ້າທົ່ວໄປ</span>
                </p>
              </b-field>
              <b-field label="ຊື່ຜູ້ສົ່ງ" horizontal>
                <b-input
                  v-model="shipment.sendername"
                  placeholder="ຊື່ຜູ້ສົ່ງ"
                  required
                />
              </b-field>
              <b-field label="ໝາຍເຫດ" horizontal>
                <b-input
                  type="textarea"
                  v-model="shipment.remark"
                  placeholder="ໝາຍເຫດ"
                />
              </b-field>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="panel is-success">
            <div class="panel-heading">ປາຍທາງ</div>
            <div class="" style="padding: 1em">
              <b-field label="ສາຂາປາຍທາງ" horizontal>
                <multiselect
                  v-model="shipment.to_branch"
                  :options="branches"
                  track-by="id"
                  :custom-label="branchOptions"
                  label="name"
                  placeholder="ສາຂາປາຍທາງ"
                  select-label=""
                  selected-label=""
                  deselect-label=""
                >
                </multiselect>
              </b-field>
              <b-field label="ເບີໂທຜູ້ຮັບ" horizontal>
                <b-input
                  v-model="shipment.receivertel"
                  placeholder="20xxxxxxxx"
                  required
                />
              </b-field>
              <b-field label="ຊື່ຜູ້ຮັບ" horizontal>
                <b-input
                  v-model="shipment.receivername"
                  placeholder="ຊື່ຜູ້ຮັບ"
                  required
                />
              </b-field>
              <b-field label="" horizontal>
                <b-checkbox
                  v-model="shipment.is_receiver_pay"
                  :native-value="false"
                  :type="'boolean'"
                  @input="onCheckSenderPay"
                >
                  ເກັບຄ່າສົ່ງປາຍທາງ
                </b-checkbox>
              </b-field>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="columns">
        <div class="column">
          <div class="panel is-warning">
            <div class="panel-heading">
              ເຄື່ອງຝາກ
              <div class="buttons is-pulled-right">
                <b-button
                  class="is-pulled-right"
                  @click="addParcel(false)"
                  icon-left="plus"
                >
                </b-button>
                <file-picker class="is-pulled-right" @input="onupload" />
                <b-button
                  v-if="uploadItem"
                  class="is-pulled-right"
                  @click="viewPhoto"
                  icon-left="image"
                >
                </b-button>
              </div>
            </div>
            <b-table
              v-if="shipment.parcels"
              :striped="true"
              :hoverable="true"
              :data="shipment.parcels"
              :pagination-rounded="true"
            >
              <b-table-column label="#" v-slot="props">
                {{ props.index + 1 }}
              </b-table-column>
              <b-table-column label="ປະເພດເຄື່ອງ" v-slot="props">
                <multiselect
                  v-model="shipment.parcels[props.index]['parceltype']"
                  :options="parceltypes"
                  track-by="id"
                  :custom-label="parceltypeOptions"
                  label="name"
                  placeholder="ເລືອກ"
                  select-label=""
                  selected-label=""
                  deselect-label=""
                >
                </multiselect>
                <div
                  v-if="
                    shipment.parcels[props.index]['parceltype'] &&
                    shipment.parcels[props.index]['parceltype'].id === 0
                  "
                >
                  <b-field>
                    <b-input
                      v-model="shipment.parcels[props.index].remark"
                      placeholder="ກະລຸນາປ້ອນປະເພດ"
                    />
                  </b-field>
                </div>
              </b-table-column>
              <b-table-column label="ຂະໜາດ" v-slot="props">
                <b-field>
                  <b-field label="ກວ້າງ" label-position="on-border">
                    <b-input
                      v-model="shipment.parcels[props.index].width"
                      placeholder="ກວ້າງ"
                      type="number"
                    />
                  </b-field>
                  <b-field label="ຍາວ" label-position="on-border">
                    <b-input
                      v-model="shipment.parcels[props.index].long"
                      placeholder="ຍາວ"
                      type="number"
                    />
                  </b-field>
                  <b-field label="ສູງ" label-position="on-border">
                    <b-input
                      v-model="shipment.parcels[props.index].height"
                      placeholder="ສູງ"
                      type="number"
                    />
                  </b-field>
                </b-field>
              </b-table-column>

              <b-table-column label="ນໍ້າໜັກ (ກິໂລ)" v-slot="props">
                <b-input
                  v-model="shipment.parcels[props.index].weight"
                  placeholder="ນໍ້າໜັກ"
                  type="number"
                />
              </b-table-column>
              <b-table-column label="ຈຳນວນ" v-slot="props">
                <b-input
                  v-model="shipment.parcels[props.index].qty"
                  placeholder="ສູງ"
                  type="number"
                />
              </b-table-column>
              <b-table-column label="ຄ່າຂົນສົ່ງ (x1000)" v-slot="props">
                <b-input
                  v-model="shipment.parcels[props.index].amount"
                  placeholder="ກີບ"
                  type="number"
                />
              </b-table-column>
              <b-table-column label="ຄ່າຂົນສົ່ງ (ບາດ)" v-slot="props">
                <b-input
                  v-model="shipment.parcels[props.index].amount_thb"
                  placeholder="ບາດ"
                  type="number"
                />
              </b-table-column>
              <b-table-column label="ລຶບ" v-slot="props">
                <b-button
                  v-if="code || props.index > 0"
                  class="is-danger"
                  @click="removeParcel(props.index)"
                  icon-left="delete"
                >
                </b-button>
              </b-table-column>
            </b-table>
            <div class="columns" style="font-size: 1.5em">
              <div class="column has-text-centered">ລວມຄ່າສົ່ງ</div>
              <div
                class="
                  column
                  has-text-grey-light
                  is-size-5
                  has-text-centered
                  is-italic
                "
                v-if="'REJECT,FORWARD'.includes(command)"
              >
                ຄ່າສົ່ງຈາກລາຍການຕົ້ນ
                {{
                  (shipment.amount_thb && shipment.amount_thb > 0
                    ? shipment.amount_thb
                    : shipment.amount) | formatnumber
                }}
                {{
                  shipment.amount_thb && shipment.amount_thb > 0 ? "ບາດ" : "ກີບ"
                }}
              </div>
              <div
                class="column has-text-centered"
                v-if="getShipmentAmount() > 0"
              >
                {{ getShipmentAmount() | formatnumber }} ກີບ
              </div>
              <div
                class="column has-text-centered"
                v-if="getShipmentAmountThb() > 0"
              >
                {{ getShipmentAmountThb() | formatnumber }} ບາດ
              </div>
              <div
                class="column is-1"
                style="font-size: 0.8em"
                v-if="!shipment.is_receiver_pay"
              >
                ວິທີຊຳລະ
              </div>
              <div class="column" v-if="!shipment.is_receiver_pay">
                <multiselect
                  v-model="selectedPayment"
                  :options="paymentmethods"
                  track-by="id"
                  label="name"
                  placeholder="ວີທີຊຳລະ"
                  select-label=""
                  selected-label=""
                  deselect-label=""
                  @input="onPaymentMethodSelected"
                >
                </multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns" v-if="shipment.customer_id">
        <div class="column">
          <hr />
          <div class="panel is-info">
            <div class="panel-heading">
              <b-checkbox
                v-model="shipment.is_cod"
                :native-value="false"
                :type="'boolean'"
                @input="onCheckCod"
              >
                COD
              </b-checkbox>
              <b-checkbox
                style="margin-left: 3em"
                v-if="shipment.is_cod && shipment.is_receiver_pay"
                v-model="shipment.is_fee_in_cod"
                :native-value="false"
                :type="'boolean'"
                @input="onCheckFeeInCod"
              >
                ຫັກຄ່າສົ່ງຈາກ COD
              </b-checkbox>
            </div>
            <b-table
              v-if="shipment.is_cod"
              :striped="true"
              :hoverable="true"
              :data="shipment.cods"
              :pagination-rounded="true"
            >
              <b-table-column label="ປ້ອນຈຳນວນເງິນ" v-slot="props">
                <b-field>
                  <p class="control">
                    <span class="button is-static">
                      {{ props.row.ccy }}
                    </span>
                  </p>
                  <b-input
                    class="is-large has-text-right"
                    v-model="shipment.cods[props.index].amountx"
                    placeholder="ຈຳນວນເງິນ"
                    type="number"
                  />
                  <p class="control" v-if="!isAndroid">
                    <span
                      v-if="props.row.ccy == 'LAK'"
                      class="button is-static"
                    >
                      x 1,000
                    </span>
                  </p>
                </b-field>
              </b-table-column>

              <b-table-column
                label="ມຸນຄ່າCODຈາກລາຍການຕົ້ນ"
                v-slot="props"
                v-if="command && 'REJECT,FORWARD'.includes(command)"
              >
                <span
                  v-if="props.row.ccy == 'LAK'"
                  class="
                    has-text-grey-light
                    is-size-6
                    has-text-centered
                    is-italic
                  "
                >
                  {{
                    (shipment.cods[props.index].amountx * 1000) | formatnumber
                  }}
                </span>
                <span
                  v-if="props.row.ccy == 'THB'"
                  class="
                    has-text-grey-light
                    is-size-6
                    has-text-centered
                    is-italic
                  "
                >
                  {{ (shipment.cods[props.index].amountx * 1) | formatnumber }}
                </span>
              </b-table-column>

              <b-table-column label="ຫັກຄ່າສົ່ງ" v-slot="props">
                <span v-if="props.row.ccy == 'LAK'">
                  {{ getShipmentAmount() | formatnumber }}
                </span>
                <span v-if="props.row.ccy == 'THB'">
                  {{ getShipmentAmountThb() | formatnumber }}
                </span>
              </b-table-column>
              <b-table-column label="ລວມ" v-slot="props">
                <div v-if="shipment.cods[props.index].ccy == 'LAK'">
                  {{
                    shipment.cods[props.index].amountx * 1000 -
                    (shipment.is_fee_in_cod ? getShipmentAmount() : 0)
                  }}
                </div>
                <div v-if="shipment.cods[props.index].ccy == 'THB'">
                  {{
                    shipment.cods[props.index].amountx * 1 -
                    (shipment.is_fee_in_cod ? getShipmentAmountThb() : 0)
                  }}
                </div>
              </b-table-column>
            </b-table>
          </div>
        </div>
      </div>
      <hr />
      <div class="has-text-centered">
        <b-button 
        class="button is-success is-large"
        :loading="isLoading" 
         @click="save()"
         >
          <b-icon icon="check" custom-size="default"></b-icon>
          <span>ບັນທຶກ</span>
        </b-button>
      </div>
    </section>

    <div class="modal is-active is-large" v-if="isShowModalImage">
      <div class="modal-background"></div>
      <div class="modal-content">
        <img :src="'http://localhost:3900/public/' + uploadItem.filename" />
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="isShowModalImage = false"
      ></button>
    </div>

    <div class="modal is-active is-large" v-if="isShowCustomers">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">ຊອກຫາຂໍ້ມູນໃບບິນລູກຄ້າ</p>
          <button
            class="delete"
            aria-label="close"
            @click="closeModal()"
          ></button>
        </header>
        <section class="modal-card-body">
          <div class="columns">
            <div class="column">
              <b-input
                v-model="searchingCode"
                placeholder="ເລກໃບບິນ, ເບີໂທ, ຊື່"
                @keyup.native.enter="searchCustomers"
              />
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-tabs v-model="activeTab" type="is-boxed">
                <b-tab-item
                  :label="
                    'ໃບບິນ' +
                    (shipments && shipments.length > 0
                      ? ` (${shipments.length})`
                      : '')
                  "
                >
                  <table
                    class="table is-fullwidth"
                    :key="s"
                    v-if="shipments && shipments.length > 0"
                  >
                    <thead>
                      <tr>
                        <th>ເລກໃບບິນ</th>
                        <th>ຕົ້ນທາງ</th>
                        <th>ປາຍທາງ</th>
                        <th>ສາຂາປາຍທາງ</th>
                        <th style="width: 40px"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in shipments" :key="i">
                        <td>{{ item["code"] }}</td>
                        <td>
                          {{ item["sendertel"] }} {{ item["sendername"] }}
                        </td>
                        <td>
                          {{ item["receivertel"] }} {{ item["receivername"] }}
                        </td>
                        <td>
                          {{
                            item["branch_branchToshipment_to_branch_id"]["code"]
                          }}
                          {{
                            item["branch_branchToshipment_to_branch_id"]["name"]
                          }}
                        </td>
                        <td>
                          <b-button
                            class="is-success is-small"
                            @click="selectShipment(item)"
                            icon-left="check"
                          >
                          </b-button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-tab-item>
                <b-tab-item
                  :label="
                    'ລູກຄ້າ' +
                    (customers && customers.length > 0
                      ? ` (${customers.length})`
                      : '')
                  "
                >
                  <table
                    class="table is-fullwidth"
                    :key="c"
                    v-if="customers && customers.length > 0"
                  >
                    <thead>
                      <tr>
                        <th>ເບີໂທ</th>
                        <th>ຊື່</th>
                        <th style="width: 40px"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(customer, index) in customers" :key="index">
                        <td>{{ customer["tel"] }}</td>
                        <td>{{ customer["name"] }}</td>
                        <td>
                          <b-button
                            class="is-info is-small"
                            @click="selectCustomer(customer)"
                            icon-left="check"
                          >
                          </b-button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-tab-item>
              </b-tabs>
            </div>
          </div>
        </section>
      </div>
    </div>

    <b-notification :closable="false">
      <b-loading
        :is-full-page="true"
        v-model="isLoading"
        :can-cancel="true"
      ></b-loading>
    </b-notification>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
import FilePicker from "@/components/FilePicker";
import { get } from "vuex-pathify";
import Swal from "sweetalert2";

export default {
  name: "ShipmentForm",
  components: { FilePicker, Multiselect },
  computed: {
    user: get("user"),
    branches: get("branches"),
    parceltypes: get("parceltypes"),
    exchangerate: get("exchangerate"),
    paymentmethods() {
      const items = this.$store.get("paymentmethods");
      return this._.filter(items, (s) => s.id !== "selftransfer");
    },
  },
  data() {
    return {
      c: 0,
      s: 0,
      key: 0,
      code: null,
      activeTab: 0,
      isLoading: false,
      shipment: {
        sendertel: null,
        parcels: [],
        is_cod: false,
        cods: [{ ccy: "LAK" }, { ccy: "THB" }],
        fee_payment_mode: "cash",
      },
      file: null,
      uploadPercent: 0,
      uploadItem: null,
      isShowCustomers: false,
      isShowModalImage: false,
      isShowPopupParcel: false,
      searchingCode: null,
      allcustomers: null,
      customer: null,
      customers: null,
      shipments: null,
      parcel: null,
      selectedPayment: null,
      command: null,
    };
  },
  mounted() {
    this.command = this.$route.query["command"];
    this.code = this.$route.params["code"];
    if (this.code) {
      axios.get("shipments/get/" + this.code).then((r) => {
        if (this.command && this.command === "REJECT") {
          r.original_shipment = r.id;
          r.original_remark = r.remark;
          delete r.id;
          delete r.cod;
          r.is_cod = false;
          //reverse branch info
          const branchToShipFrom = r.branch_branchToshipment_from_branch_id;
          const branchToShipTo = r.branch_branchToshipment_to_branch_id;

          r.branch_branchToshipment_from_branch_id = branchToShipTo;
          r.branch_branchToshipment_to_branch_id = branchToShipFrom;
          r.to_branch_id = branchToShipFrom.id;
          r.from_branch_id = branchToShipTo.id;

          r.receivername = r.sendername;
          r.receivertel = r.sendertel;
          r.shipment_type = "SRJ";
          r.remark = r.hold_reason;

          /*
          if(r.is_receiver_pay===true){
            r.parcel.map(e=>{
              if(e.amount) e.amount=e.amount*2;
              if(e.amount_thb)e.amount_thb=e.amount_thb*2;
              return e;
            });
          }else{
            r.is_receiver_pay=true;
          }
          */
        } else if (this.command && this.command === "FORWARD") {
          r.original_shipment = r.id;
          r.original_remark = r.remark;
          delete r.id;

          const branchToShipTo = r.branch_branchToshipment_to_branch_id;
          r.branch_branchToshipment_from_branch_id = branchToShipTo;
          r.from_branch_id = branchToShipTo.id;
          delete r.branch_branchToshipment_to_branch_id;
          delete r.to_branch_id;
          r.remark = "ເຄື່ອງຖືກສົ່ງຕໍ່";

          r.is_receiver_pay = true;
          /*
          const parcelCount = r.parcel.length;
          r.parcel.map(e=>{
             if(r.is_receiver_pay===true){
               if(e.amount) e.amount=e.amount*2;
               if(e.amount_thb)e.amount_thb=e.amount_thb*2;
               if(r.is_cod){
                 r.is_fee_in_cod=true;
               }
             }else{
               r.is_receiver_pay=true;
               if(e.amount && r.amount) e.amount=(r.amount/1000)/parcelCount;
               if(e.amount_thb && r.amount_thb)e.amount_thb=r.amount_thb/parcelCount;
             }
            return e;
          });
            */
          r.shipment_type = "SFW";
        }
        this.shipment = r;
        if (r.branch_branchToshipment_from_branch_id) {
          this.shipment.from_branch = this._.find(
            this.branches,
            (b) => b.id === r.branch_branchToshipment_from_branch_id.id
          );
        }
        if (r.branch_branchToshipment_to_branch_id) {
          this.shipment.to_branch = this._.find(
            this.branches,
            (b) => b.id === r.branch_branchToshipment_to_branch_id.id
          );
        }
        this.parcel = r["parcel"];
        this.selectedPayment = this._.find(
          this.paymentmethods,
          (itm) => itm.id.toLowerCase() === r.fee_payment_mode.toLowerCase()
        );
        this.shipment.parcels = this.shipment.parcel;
        this.shipment.cods = [{ ccy: "LAK" }, { ccy: "THB" }];
        if (this.shipment.is_cod && this.shipment.cod.length > 0) {
          for (let index = 0; index < this.shipment.cods.length; index++) {
            const temp = this._.find(
              this.shipment.cod,
              (c) => c.ccy === this.shipment.cods[index].ccy
            );
            if (temp) {
              this.shipment.cods[index] = temp;
            }
            if (this.shipment.is_fee_in_cod) {
              if (this.shipment.cods[index].ccy === "LAK") {
                this.shipment.cods[index].amount += this.shipment.amount;
              } else {
                this.shipment.cods[index].amount += this.shipment.amount_thb;
              }
            }
            this.shipment.cods[index].amountx =
              this.shipment.cods[index].amount;
            if (this.shipment.cods[index].ccy === "LAK") {
              this.shipment.cods[index].amountx =
                this.shipment.cods[index].amount / 1000;
            }
          }
        }
        if (this.shipment.customer) {
          this.shipment.sendercode = this.shipment.customer.code;
        }
        if (this.shipment.customer) {
          this.customer = this.shipment.customer;
        }

        if (r["photo"]) {
          this.uploadItem = {
            filename: r["photo"],
          };
        }

        if (this.command && "REJECT,FORWARD".includes(this.command)) {
          Swal.fire(
            "ຄຳເຕືອນ",
            "ກະລຸນາກວດຄືນ ຂໍ້ມູນ ແລະ ຄ່າສົ່ງ ທີ່ລະບົບປ້ອນໃຫ້ ວ່າຖືກຕ້ອງແລ້ວຫຼືບໍ່ ກ່ອນບັນທຶກ",
            "warning"
          );
        }
      });
    } else {
      if (!this.shipment) this.shipment = {};
      this.shipment.from_branch = this.user.branch;
      if (!this.shipment.parcels) this.shipment.parcels = [];
      this.shipment.parcels.push({
        parceltype: this.parceltypes[0],
        width: 1,
        long: 1,
        height: 1,
        weight: 1,
        qty: 1,
        photo: null,
        amount: null,
        amount_thb: null,
      });
      this.selectedPayment = this.paymentmethods[0];
    }
  },
  methods: {
    onCheckCod(e) {
      console.log(e);
    },
    branchOptions({ code, name }) {
      return `${code} — ${name}`;
    },
    parceltypeOptions({ name }) {
      return `${name}`;
    },
    viewPhoto() {
      this.isShowModalImage = true;
    },
    selectShipment(shipment) {
      this.isShowCustomers = false;
      const u = `/shipment/${shipment.code}`;
      this.$router.push({ path: u, force: true });
      this.key++;
      this.$forceUpdate();
    },
    searchCustomer() {
      this.customer = null;
      this.shipment.customer_id = null;
      if (this.shipment.sendertel) {
        axios.get("customers/get/" + this.shipment.sendertel).then((r) => {
          if (r) {
            this.setCustomer(r);
            this.key++;
          }
        });
      }
    },
    searchCustomers(e) {
      console.log(e);
      if (this.searchingCode) {
        axios.get(`shipments/search/${this.searchingCode}`).then((r) => {
          if (r) {
            this.shipments = r;
            this.s++;
          }
        });
        axios.get(`customers/search/${this.searchingCode}/20/0`).then((r) => {
          if (r) {
            this.customers = r.models;
            this.c++;
          }
        });
      }
    },
    onCheckSenderPay(e) {
      console.log(e);
    },
    onCheckFeeInCod(e) {
      console.log(e);
      if (this.shipment.is_fee_in_cod) {
        this.shipment.is_receiver_pay = true;
      }
    },
    getShipmentAmount() {
      var sum = 0;
      this.shipment.parcels.forEach((p) => {
        sum += p.amount * 1000 * p.qty;
      });
      if (sum > 1000000) {
        this.$buefy.snackbar.open({
          message: "ຄ່າຂົນສົ່ງຜິດປົກກະຕິ, ທ່ານໝັ້ນໃຈບໍ?",
          queue: false,
        });
      }
      return sum;
    },
    getShipmentAmountThb() {
      var sum = 0;
      this.shipment.parcels.forEach((p) => {
        sum += p.amount_thb * p.qty;
      });
      if (sum > 3000) {
        this.$buefy.snackbar.open({
          message: "ຄ່າຂົນສົ່ງຜິດປົກກະຕິ, ທ່ານໝັ້ນໃຈບໍ?",
          queue: false,
        });
      }
      return sum;
    },
    removeParcel(index) {
      if (this.shipment.parcels && this.shipment.parcels[index]) {
        this.shipment.parcels.splice(index, 1);
        this.$nextTick();
      }
    },
    addParcel() {
      if (!this.shipment.parcels) {
        this.shipment.parcels = [];
      }
      this.shipment.parcels.push({
        parceltype: this.parceltypes[0],
        width: 1,
        long: 1,
        height: 1,
        weight: 1,
        photo: null,
        amount: null,
        amount_thb: null,
      });
    },
    showCustomers() {
      this.isShowCustomers = true;
    },
    closeModal() {
      this.isShowCustomers = false;
    },
    selectCustomer(customer) {
      if (this.customers && customer) {
        this.setCustomer(customer);
        this.isShowCustomers = false;
      }
    },
    setCustomer(customer) {
      this.customer = customer;
      this.shipment.customer_id = customer.id;
      this.shipment.sendercode = customer.code;
      this.shipment.sendertel = customer.tel;
      this.shipment.sendername = customer.name;
    },
    save() {
      var validated = true;
      if (!this.shipment.parcels || this.shipment.parcels.length === 0) {
        validated = false;
        this.$buefy.snackbar.open({
          type: "is-warning",
          message: `ກະລຸນາປ້ອນຂໍ້ມູນເຄື່ອງຝາກ`,
          queue: false,
        });
      }
      if (!this.selectedPayment || !this.shipment.fee_payment_mode) {
        validated = false;
        this.$buefy.snackbar.open({
          type: "is-warning",
          message: `ກະລຸນາເລືອກວິທີຊຳລະເງິນ`,
          queue: false,
        });
      }
      this.shipment.parcels.forEach((element) => {
        ["parceltype", "width", "long", "height", "weight", "qty"].forEach(
          (key) => {
            if (!element[key]) {
              var label = "";
              switch (key) {
                case "parceltype":
                  label = "ປະເພດເຄື່ອງຝາກ";
                  break;
                case "width":
                  label = "ຄວາມກວ້າງ";
                  break;
                case "long":
                  label = "ຄວາມຍາວ";
                  break;
                case "height":
                  label = "ຄວາມສູງ";
                  break;
                case "weight":
                  label = "ນໍ້າໜັກ";
                  break;
                case "qty":
                  label = "ຈຳນວນ";
                  break;
              }

              validated = false;
              this.$buefy.snackbar.open({
                type: "is-warning",
                message: `${label} ບໍ່ສາມາດເປົ່າວ່າງ`,
                queue: false,
              });
            }
          }
        );
        if (element["parceltype"]["id"] === 0 && !element["remark"]) {
          validated = false;
          this.$buefy.snackbar.open({
            type: "is-warning",
            message: `ຕ້ອງປ້ອນປະເພດເຄື່ອງຝາກ`,
            queue: false,
          });
        }
        /* if (
           element["parceltype"] &&
           element["parceltype"] === 0 &&
           (!element["remark"] || element["remark"].trim() === "")
         ) {
           validated = false;
           this.$buefy.snackbar.open({
             message: `ກະລຸນາປ້ອນຊື່ປະເພດເຄື່ອງຝາກ`,
             type: "is-warning",
             queue: false,
           });
         }*/
        if (!element["amount"] && !element["amount_thb"]) {
          validated = false;
          this.$buefy.snackbar.open({
            type: "is-warning",
            message: `ຕ້ອງປ້ອນຄ່າສົ່ງ`,
            queue: false,
          });
        }
      });

      this.shipment.amount = this.getShipmentAmount();
      this.shipment.amount_thb = this.getShipmentAmountThb();

      ["sendername", "sendertel", "receivertel", "receivername"].forEach(
        (key) => {
          if (!this.shipment[key]) {
            var label = "";
            switch (key) {
              case "sendername":
                label = "ຊື່ຜູ້ສົ່ງ";
                break;
              case "sendertel":
                label = "ເບີໂທຜູ້ສົ່ງ";
                break;
              case "receivertel":
                label = "ເບີໂທຜູ້ຮັບ";
                break;
              case "receivername":
                label = "ເບີໂທຜູ້ຮັບ";
                break;
            }
            validated = false;
            this.$buefy.snackbar.open({
              type: "is-warning",
              message: `${label} ບໍ່ສາມາດເປົ່າວ່າງ`,
              queue: false,
            });
          }
        }
      );

      if (
        !this.shipment.sendertel ||
        !this.shipment.sendertel.match(/^\d{6,}$/g)
      ) {
        validated = false;
        this.$buefy.snackbar.open({
          type: "is-warning",
          message: `ເບີໂທບໍ່ຖືກຕ້ອງ ກະລຸນາປ້ອນ 20xxxxxxxx ຫຼື 30xxxxxxx ຫຼື xxxxxxxx`,
          queue: false,
        });
      }
      if (
        !this.shipment.sendertel ||
        !this.shipment.receivertel.match(/^\d{6,}$/g)
      ) {
        validated = false;
        this.$buefy.snackbar.open({
          type: "is-warning",
          message: `ເບີໂທບໍ່ຖືກຕ້ອງ ກະລຸນາປ້ອນ 20xxxxxxxx ຫຼື 30xxxxxxx ຫຼື xxxxxxxx`,
          queue: false,
        });
      }

      if (this.shipment.is_cod) {
        if (!this.shipment.cods || this.shipment.cods.length == 0) {
          validated = false;
          this.$buefy.snackbar.open({
            type: "is-warning",
            message: `ກະລຸນາປ້ອນຂໍ້ມູນ COD`,
            queue: false,
          });
        }

        // check if cod LAK is input (mandatory)
        const codLak = this._.find(
          this.shipment.cods,
          (c) => c.ccy.toLowerCase() == "lak"
        );
        if (!codLak || !codLak.amountx || codLak.amountx == 0) {
          validated = false;
          this.$buefy.snackbar.open({
            type: "is-warning",
            message: `ກະລຸນາປ້ອນຂໍ້ມູນ COD ຈຳນວນກີບ`,
            queue: false,
          });
        }
      }

      if (!validated) {
        return;
      }
      if (!this.shipment.to_branch) {
        this.$buefy.snackbar.open({
          message: "ກະລຸນາເລືອກ ສາຂາປາຍທາງ",
          type: "is-warning",
          queue: false,
        });
        return;
      }
      if (this.shipment.from_branch.id === this.shipment.to_branch.id) {
        this.$buefy.snackbar.open({
          message: "ສາຂາຕົ້ນທາງ ກັບ ສາຂາປາຍທາງ ບໍ່ສາມາດຄືກັນ",
          type: "is-warning",
          queue: false,
        });
        return;
      }

      this.isLoading = true;
      if (this.uploadItem) {
        this.shipment.photo = this.uploadItem.filename;
      }
      if (this.shipment.is_cod && this.shipment.cods) {
        this._.map(this.shipment.cods, (c) => {
          if (c.ccy === "LAK") {
            c.amount = c.amountx * 1000;
            if (this.shipment.is_fee_in_cod) {
              c.amount = c.amount - this.shipment.amount;
            }
          } else {
            c.amount = c.amountx * 1;
            if (this.shipment.is_fee_in_cod) {
              c.amount = c.amount - this.shipment.amount_thb;
            }
          }
          if (
            this.customer != null &&
            this.customer.customer_account !== null &&
            this.customer.customer_account.length > 0
          ) {
            const account = this._.find(
              this.customer.customer_account,
              (a) => a.ccy === c.ccy
            );
            if (account) {
              c.account = account.account;
            }
          }
        });
      }
      this.shipment["payment"] = {
        paymentMethod: this.selectedPayment,
        remark: JSON.stringify(this.selectedPayment),
      };
      this.shipment["exchangerate"] = this.exchangerate;
      if (this.shipment.is_fee_in_cod) {
        this.shipment.is_receiver_pay = true;
      }

      const req = this.shipment.id
        ? axios.put("shipments/update/" + this.shipment.id, this.shipment)
        : axios.post("shipments/add", this.shipment);
      req
        .then((res) => {
          this.isLoading = false;
          Swal.fire("ສຳເລັດ", "", "success").then(() => {
            this.$router.push({ path: "/shipment/" + res.code });
          });
        })
        .catch((e) => {
          this.isLoading = false;
          Swal.fire("ພົບບັນຫາ", e, "warning");
        });

/*
        this.isLoading=true;
        for(let test=1; test<2000;test++){
          console.log(`Start test: ${test}`);
          axios.post("shipments/add", this.shipment).then((res) => {
                console.log(`test ${test}: ${res.id}`);
                })
                .catch((e) => { console.log(`${e.message}`);
                });
        }
        console.log(`End test`);
        this.isLoading=false;
        */
       
    },
    onupload(file) {
      let formData = new FormData();
      formData.append("file", file);
      axios
        .post("upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: this.progressEvent,
        })
        .then((r) => {
          this.uploadItem = r;
        })
        .catch((err) => {
          this.$buefy.toast.open({
            message: `Error: ${err.message}`,
            type: "is-danger",
          });
        });
    },
    progressEvent(progressEvent) {
      this.uploadPercent = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
    },
    onPaymentMethodSelected(selectedMode) {
      this.selectedPayment = selectedMode;
      this.shipment.fee_payment_mode = null;
      if (selectedMode) this.shipment.fee_payment_mode = selectedMode.id;
    },
    async onChangeParceltype(index) {
      const e = this.shipment.parcels[index].parceltype;
      if (e && e.id === 0) {
        const name = await Swal.fire({
          title: "ປະເພດ",
          input: "text",
          inputPlaceholder: "ປະເພດເຄື່ອງຝາກ",
          showCloseButton: true,
        });
        this.shipment.parcels[index].remark = name.value;
      }
    },
  },
};
</script>
<style>
.table__wrapper {
  overflow-x: auto;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
